.home__bg{
    background-image: url(../../img/bg.jpg);
    background-color: #010101;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}
li.nav__items{
    font-size: 18px;
    padding-bottom: 15px;
}
.home__content{
    padding: 125px 0px;
}
h1.home__text{
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 3px;
    color: rgba(198,201,216,.75)!important;
}
h2.home__text{
    font-size: 54px;
    line-height: 54px;
    font-weight: 800;
    font-family: Montserrat,sans-serif;
}
h3.home__text{
    font-size: 50px;
    font-weight: 800;
    font-family: Montserrat,sans-serif;
    line-height: 40px;
}
h4.home__text{
    font-size: 50px;
    font-weight: 800;
    line-height: 40px;
    font-family: Montserrat,sans-serif;
    
}
.header{
    transition: .4s all ease;
}
.header.active{
    position: fixed;
    z-index: 1000;
    width: 100%;
    padding: 0px 30px;
    background-color: #191919;
}

/* RESPONSIVE */
@media only screen and (max-width: 768px){
 .header{
     justify-content: space-between;
 }
 .navigation.pxy__30 {
        display: none;
    }
h2.white.pz__10 {
    font-size: 50px!important;
}
svg.bi.bi-justify.white.pointer {
    width: 30px;
    height: 30px;
}
}
@media only screen and (max-width: 425px){
    h2.home__text.pz__10{
    font-size: 32px;
}
h3.sweet.pz__10 {
    font-size: 40px;
}
h1.home__text {
    font-size: 14px;
}
h4.home__text {
    font-size: 45px;
}
}