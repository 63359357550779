.toggle__menu {
    display: flex;
    justify-content: flex-end;
    float: right;
    z-index: 1000;
    width: 100%;
}
ul.sidebar.d__flex {
    flex-wrap: wrap;
    height: 100%;
    position: fixed;
    right: 0%;
    background: #010101;
    justify-content: center;
    width: 300px;
    top: 0%;
    flex-direction: column;
}

li.sideNavbar{
    padding: 25px 30px;
}